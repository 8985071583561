<script setup lang="ts">
import type { Category } from '~/src/classes.ts';

const filter = defineModel<string>();
const filterCategory = defineModel<string>('category');

defineProps<{
    categories?: Category[] | undefined;
    submitButton?: boolean;
}>();

const emit = defineEmits<{
    submitClicked: [],
}>();

const filterInput = useTemplateRef<HTMLInputElement>('filterInput');

const { $translator: translator } = useNuxtApp();
const allCategory: Category = { key: '', text: translator.translate('crud.all'), icon: 'clipboard-list' };
</script>

<template>
    <section class="sticky-top pt-md-2">
        <div class="input-group bg-white">
            <span class="input-group-text">
                <Icon v="filter" />
            </span>
            <input
                ref="filterInput"
                v-model="filter"
                type="search"
                class="form-control border-primary"
                :placeholder="$t('crud.filterLong')"
            >
            <button v-if="filter" class="btn btn-outline-danger" @click="filter = ''; filterInput?.focus()">
                <Icon v="times" />
            </button>
            <button
                v-if="submitButton"
                class="btn btn-success"
                @click="emit('submitClicked')"
            >
                <Icon v="plus-circle" />
                <T>nouns.submit.action</T>
            </button>
        </div>
        <div v-if="categories && categories.length > 0" class="btn-group d-flex flex-wrap flex-md-nowrap pt-1 bg-white">
            <button
                v-for="category of [allCategory, ...categories]"
                :key="category.text"
                :class="[
                    'btn btn-sm',
                    filterCategory === category.key ? 'btn-primary' : 'btn-outline-primary',
                    'd-flex justify-content-center align-items-center gap-1',
                ]"
                @click="filterCategory = category.key"
            >
                <Icon v-if="category.icon" :v="category.icon" />
                <Spelling :text="category.text" />
            </button>
        </div>
    </section>
</template>
